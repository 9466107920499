@import '../../web/fonts/fonts.less';
@import '../../web/styles/inputs.less';
@import './react-toastify.less';

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Open Sans', sans-serif;
}

body, html, #react-root {
	height: 100vh;
	width: 100vw;

	.hide {
		display: none;
		opacity: 0;
		visibility: hidden;
	}
}
